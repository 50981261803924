import React from "react";

import { Box } from "~components/Box";

import { SvgIconTick } from "~animations/__layers__/animated_icons/SvgIconTick";
import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerAuthenticationProps extends StoryblokBlok, BoxProps {
  title: string;
}

export function AnimLayerAuthentication({
  title,
  ...rest
}: AnimLayerAuthenticationProps) {
  return (
    <AnimatedElement
      animationLevel="secondary"
      {...rest}
      className={styles.authenticationPopup}
    >
      <Box width="gridSpan1" maxWidth="50%">
        <SvgIconTick />
      </Box>
      <Box className={styles.authenticationText}>{title}</Box>
    </AnimatedElement>
  );
}
