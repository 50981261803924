import React, { useContext } from "react";

import { faBolt } from "@fortawesome/sharp-regular-svg-icons/faBolt";
import { faBuildingColumns } from "@fortawesome/sharp-regular-svg-icons/faBuildingColumns";
import { faCheckCircle } from "@fortawesome/sharp-regular-svg-icons/faCheckCircle";
import { faChevronRight } from "@fortawesome/sharp-regular-svg-icons/faChevronRight";
import { faFingerprint } from "@fortawesome/sharp-regular-svg-icons/faFingerprint";
import { faShieldCheck } from "@fortawesome/sharp-regular-svg-icons/faShieldCheck";
import { faStopwatch } from "@fortawesome/sharp-regular-svg-icons/faStopwatch";
import { faWallet } from "@fortawesome/sharp-regular-svg-icons/faWallet";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimatedElement } from "~animations/animation_primitives/AnimatedElement";

import { PageQueryContext } from "~context/PageQueryContext";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";
import { linkStyle, tagStyle } from "./styles.css";

import type { AnimLayerFakeButtonProps } from "../AnimLayerFakeButton/types";
import type { IconDefinition } from "@fortawesome/sharp-regular-svg-icons";
import type { ReactNode } from "react";
import type { BoxProps } from "~components/Box";
import type { StoryblokFieldMedia } from "~types/storyblok.types";

export type BalanceCardsIconMapKey = keyof typeof ICON_MAP;

const ICON_MAP: Record<string, IconDefinition> = {
  faCheckCircle,
  faBolt,
  faWallet,
  faBuildingColumns,
  faStopwatch,
  faFingerprint,
  faShieldCheck,
} as const;

export interface AnimLayerBalanceCardItemProps extends BoxProps {
  amount?: number;
  ctaIcon?: AnimLayerFakeButtonProps["ctaIcon"];
  ctaText?: string;
  description?: string;
  icon?: BalanceCardsIconMapKey;
  tag?: string;
  title?: string;
  image?: StoryblokFieldMedia;
  details?: string;
  children?: ReactNode;
  variant?: "illustration";
}

/**
 * ------------------------------------------------------------------------------
 * Abstraction of single balance card item. Is exported in addition to
 * {@link: AnimLayerBalanceCardList} as it can be used on it's own in other animations.
 * ------------------------------------------------------------------------------
 */

export function AnimLayerBalanceCardItem({
  amount,
  className: userClassName,
  ctaIcon,
  ctaText,
  icon = "faCheckCircle",
  tag,
  title,
  image,
  details,
  children,
  variant,
  ...rest
}: AnimLayerBalanceCardItemProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};
  const dynamicIcon = ICON_MAP[icon];

  return (
    <StoryblokEditable {...rest}>
      <AnimatedElement
        animationLevel="primary"
        borderRadius="md"
        className={clsx(userClassName, variantBackgroundColor.background_sand)}
        padding="phoneSpacing2"
        height="min-content"
        position="relative"
        display="flex"
        flexDirection="column"
        gap="phoneSpacing1"
        width="100%"
        maxWidth="gridSpan4"
        {...rest}
      >
        {tag && (
          <Box className={tagStyle} textAppearance="phone_text_sm">
            {tag}
          </Box>
        )}
        <Box
          alignItems="center"
          display="flex"
          gap="phoneSpacing1"
          textAppearance="phone_text_md"
          fontWeight="semibold"
        >
          {dynamicIcon && <IconFontAwesome icon={dynamicIcon} />}
          {image && (
            <Image
              width="phoneSpacing3"
              height="phoneSpacing3"
              image={image}
              borderRadius="xs"
            />
          )}
          {title && <Box textAppearance="phone_text_sm">{title}</Box>}
          {details && (
            <Box
              className={clsx(linkStyle({ variant }))}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="phoneSpacing1"
              textAppearance="phone_text_sm"
              fontWeight="semibold"
              marginLeft="auto"
            >
              {details}
              <IconFontAwesome
                icon={faChevronRight}
                marginRight="auto"
                size="xs"
              />
            </Box>
          )}
        </Box>

        {amount && (
          <Box textAppearance="phone_h4" fontWeight="normal">
            {formatCurrencyString({ amount, lang })}
          </Box>
        )}

        {ctaText && (
          <AnimLayerFakeButton size="sm" ctaIcon={ctaIcon}>
            {ctaText}
          </AnimLayerFakeButton>
        )}
        {children}
      </AnimatedElement>
    </StoryblokEditable>
  );
}
