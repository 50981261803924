/**
 * ------------------------------------------------------------------------------
 * Abstraction of single payment method
 * ------------------------------------------------------------------------------
 */
import React from "react";

import { Box } from "~components/Box";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";

import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface AnimLayerBankSelectionItemProps extends StoryblokBlok {
  image: StoryblokFieldMedia;
  title: string;
}

export function AnimLayerBankSelectionItem({
  image,
  title,
  ...rest
}: AnimLayerBankSelectionItemProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box
        display="flex"
        alignItems="center"
        gap="phoneSpacing1"
        paddingX="phoneSpacing2"
        paddingY="phoneSpacing1.5"
        color="text_highContrast"
        textAppearance="phone_text_md"
        {...rest}
      >
        {image && (
          <Image
            width="image_sm"
            height="image_sm"
            image={image}
            borderRadius="sm"
          />
        )}
        {title && <Box marginLeft="phoneSpacing1">{title}</Box>}
      </Box>
    </StoryblokEditable>
  );
}
